import { render, staticRenderFns } from "./calendar-icon.vue?vue&type=template&id=a786d790&scoped=true&"
import script from "./calendar-icon.ts?vue&type=script&lang=ts&"
export * from "./calendar-icon.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a786d790",
  null
  
)

export default component.exports